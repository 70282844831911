import React from 'react'
import HttpsRedirect from 'react-https-redirect'
import Router from './Router'


export default function Https() {
  return (
    <HttpsRedirect>
      <Router />
    </HttpsRedirect>
  )
}
