import React, { useState, useEffect, useContext } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import styled, {ThemeProvider} from 'styled-components'
import SpecimenPane from './views/SpecimenPane'
import InfoPane from './views/InfoPane'
import Tree from './views/Tree-svg'
import data from './data/20200421-demo'
import theme from './theme'
import { CourseContext } from './Router'

export const HoverContext = React.createContext()
export const SetHoverContext = React.createContext()
export const IdContext = React.createContext()
export const SetIdContext = React.createContext()
export const NodeContext = React.createContext()
export const SetNodeContext = React.createContext()
export const LabContext = React.createContext()
export const SetLabContext = React.createContext()

const iconSize = '56px'

const Universe = styled(Box)`
  height: 100%;
  background-color: black;
  font-family: 'Cabin', sans-serif;
`

const Galaxy = props =>
  <Flex
    {...props}
    sx={{
      flexFlow:['column-reverse nowrap', 'row nowrap'],
      height: '100vh',
    }}
  />

const TreeBox = styled(Box)`
  z-index: 10;
  position: fixed;
  bottom: 50%;
  right: calc(65% - ${iconSize}/2);
`


export default function App() {
  const [lab, setLab] = useState(null)
  const [node, setNode] = useState()
  const [id, setId] = useState()
  const [hover, setHover] = useState(null)
  const course = useContext(CourseContext)
  console.log(course)

  useEffect (()=>{
    if ( course === "oeb126"){
      setLab("lab 6")
      setNode(Object.keys(data.labs["lab 6"].nodes)[0])
      setId(data.nodes["6-9-Theropoda"].default)
      setHover(data.nodes["6-9-Theropoda"].default)
    }
    // else {
    //   setLab("demo")
    //   setNode(Object.keys(data.labs["demo"].nodes)[0])
    //   setId(data.nodes["1-1-Something"].default)
    //   setHover(data.nodes["1-1-Something"].default)
    // }
  },[course])

  useEffect (() => {
    if (lab){
    setNode(Object.keys(data.labs[lab].nodes)[0])}
  },[lab])
  useEffect (() => {
    if (node){
    setId(data.nodes[node].default)}
  },[lab, node])


  if (lab === null){
    return(
      <Universe>
        <h2>Loading...</h2>
      </Universe>
    )
  }

  return (
    <SetLabContext.Provider value={setLab}>
      <LabContext.Provider value={lab}>
        <SetNodeContext.Provider value={setNode}>
          <NodeContext.Provider value={node}>
            <SetIdContext.Provider value={setId}>
              <IdContext.Provider value={id}>
                <ThemeProvider theme={theme}>
                  <Universe>
                    <TreeBox>
                      <Tree iconSize={iconSize}/>
                    </TreeBox>
                    <Galaxy>
                      <HoverContext.Provider value={hover}>
                        <SetHoverContext.Provider value={setHover}>
                          <InfoPane />
                          <SpecimenPane />
                        </SetHoverContext.Provider>
                      </HoverContext.Provider>
                    </Galaxy>
                  </Universe>
                </ThemeProvider>
              </IdContext.Provider>
            </SetIdContext.Provider>
          </NodeContext.Provider>
        </SetNodeContext.Provider>
      </LabContext.Provider>
    </SetLabContext.Provider>
  )
}
