import React, { useContext } from 'react'
import {
  Box,
  Flex,
  Heading,
} from 'rebass/styled-components'
import { Select } from '@rebass/forms'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SetCourseContext } from './Router'

const Universe = styled(Box)`
  height: 100%;
  background-color: black;
`

const Galaxy = styled(Flex)`
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const Panel = styled(Flex)`
  border: 5px solid black;
  border-radius: 10px;
  position: fixed;
  min-height: 300px;
  min-width: 600px;
  justify-content: center;
  padding-top: 75px;
`


export default function App() {
  const history = useHistory()
  const setCourse = useContext(SetCourseContext)

  const onSelect = async (e) => {
    switch (e.target.value) {
    case '-----':
      alert('Please select a course to view the prototype app.')
      break
    case 'OEB 126':
      setCourse('oeb126')
      sessionStorage.setItem('course', 'oeb126')
      history.push(`/oeb126`)
      break
    case 'GENED 1051':
      setCourse('gened1051')
      history.push(`/gened1051`)
      break
    default:
    alert('Please select a course to view the prototype app.')
    }
  }


  return (
    <Universe>
      <Galaxy>
        <Panel backgroundColor='black'>
          <Box>
            <Heading color='#61dafb' fontSize={4} fontFamily='sans-serif' mb='10px'>
              Choose a course to view
            </Heading>
            <Select
              id='lab'
              onChange={onSelect}
              defaultValue='-----'
              color='white'
            >
              <option
                key={0}>
                -----
              </option>
              <option
                key={1}>
                OEB 126
              </option>
              {/*<option
                key={2}>
                GENED 1051
              </option>*/}
            </Select>
          </Box>
        </Panel>
      </Galaxy>
    </Universe>
  )
}
