import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Https from './Https'
import * as serviceWorker from './serviceWorker'



ReactDOM.render(
    <Https />,
  document.getElementById('root'))

serviceWorker.unregister()
