import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Entry from './Entry'
import Viewer from './Viewer'


export const CourseContext = React.createContext()
export const SetCourseContext = React.createContext()


export default function NavRouter() {
  const [course, setCourse] = useState(null)

  useEffect(() => {
    const course = sessionStorage.getItem('course')
    if (course) {
      setCourse(course)
    }
  }, [course])

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <SetCourseContext.Provider value={setCourse}>
            <CourseContext.Provider value={'oeb126'}>
              <Viewer />
          </CourseContext.Provider>
        </SetCourseContext.Provider>
        </Route>
        <Route exact path='/gened1051'>
          <SetCourseContext.Provider value={setCourse}>
            <CourseContext.Provider value={course}>
              <Viewer />
            </CourseContext.Provider>
          </SetCourseContext.Provider>
        </Route>
        <Route exact path='/oeb126'>
          <SetCourseContext.Provider value={setCourse}>
            <CourseContext.Provider value={course}>
              <Viewer />
            </CourseContext.Provider>
          </SetCourseContext.Provider>
        </Route>
        <Route path='/lab/:title/:node'>
          <SetCourseContext.Provider value={setCourse}>
            <CourseContext.Provider value={course}>
              <Viewer />
            </CourseContext.Provider>
          </SetCourseContext.Provider>
        </Route>
      </Switch>
    </Router>
  )
}
